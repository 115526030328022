
import { Component, Vue } from 'vue-property-decorator'
import { KeepAlive } from '@/utils/decorators'
import Details from './CorrectListDetails.vue'

@KeepAlive
@Component({
  name: 'CorrectList',
  components: {
    Details
  }
})
export default class CorrectList extends Vue {
  private searchInfo = {
    projectId: '',
    createTime: '',
    teamName: ''
  }

  private total = 0
  private page = 1
  private size = 10
  private loading = false
  private tableData = []

  private id = ''
  private detailsShow = false

  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.loadData()
  }

  loadData () {
    // console.log('loadData')
    this.loading = true
    this.$axios.get(this.$apis.laborManage.selectYhTeamSignModifyLogByPage,
      {
        ...this.searchInfo,
        page: this.page,
        size: this.size
      }).then(res => {
      this.tableData = res.list || []
      this.total = res.total || 0
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.loadData()
  }

  onExport () {
    const loading = this.$loading({
      lock: true,
      text: '下载中...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    this.$axios.downloadFile(this.$apis.recruitUse.exportWorkerDutyCount, this.searchInfo).finally(() => {
      loading.close()
    })
  }

  onDetail (id: any) {
    this.id = id
    this.detailsShow = true
  }
}
