
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'

@Component({
  name: 'CorrectListDetails'
})
export default class extends Vue {
  @Prop() readonly id!: string
  @Prop() readonly detailsShow!: boolean

  private loading = false
  private info = {}

  @Watch('detailsShow')
  isDetail (val: string) {
    val && this.getDetail()
  }

  getDetail () {
    // this.loading = true
    this.$axios.get(this.$apis.laborManage.selectYhTeamSignModifyLogByModifyId, { modifyId: this.id }).then(res => {
      if (res) {
        this.info = res
      }
    }).finally(() => {
      // this.loading = false
    })
  }

  handleClose () {
    // console.log('关闭弹窗')
    this.$emit('update:detailsShow', false)
    this.info = {}
  }
}
